<!--suppress ALL -->
<template>
  <div :class="paywallType">
    <row>
      <column
        class="d-print-none d-none d-sm-block"
        sm="3">
      </column>
      <column
        xs="12"
        sm="9"
        md="6"
        class="article-center-column">
        <article-byline/>
        <div class="article-body-preview">
          <div
            :data-io-article-url="articlePath"
            class="article-body">
            <p v-html="paywallContent.bodyText"></p>
          </div>
          <div class="preview-gradient"></div>
        </div>
        <Paywall
          :target-query="paywallTargetQuery"/>
      </column>
    </row>

  </div>
</template>
<script>
import { ArticleComponents } from 'global-components';
import { getPaywallContent } from '../../server/core/paywallContentProcessor';
import { emit } from 'global-hackable';

export default {
  name: 'article-paywall-page',
  components: {
    ...ArticleComponents,
  },
  props: {
    errorCode: {
      type: Number,
      required: false,
      default: 401
    }
  },
  data() {
    return {
      paywallContent: getPaywallContent(this.$store.state.article),
      paywallTargetQuery:
        `?articleUrl=${this.$store.state.article.canonicalUrl.replace(/^(?:\/\/|[^/]+)*/, '')}&error_code=${this.errorCode}`,
      articlePath: this.$store.state.article.canonicalUrl,
      paywallType: this.$route.meta.pageContext.paywallType,
    };
  },
  async created() {
    await emit('created:ArticlePaywallPage', this);
  }
};
</script>
