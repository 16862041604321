import { extractTextFromHtml } from 'global-utils';

export function getPaywallContent(articleObj) {
  var title = '';
  var leadText = '';
  var bodyText = '';

  const articleMeta = articleObj.meta;

  // preparing article title
  const articleTitle = (typeof articleObj.title !== 'undefined') ? articleObj.title : '';
  const articleTitleWords = articleTitle.match(/("[^"]+"|[^"\s]+)/g);
  const articleTitleLength = (articleTitleWords && articleTitleWords.length) || 0;

  // preparing article lead text
  const articleLeadText = (typeof articleObj.leadText !== 'undefined') ? extractTextFromHtml(articleObj.leadText) : '';
  const articleLeadTextWords = articleLeadText ? articleLeadText.match(/("[^"]+"|[^"\s]+)/g) : [];
  const articleLeadTextLength = (articleLeadTextWords && articleLeadTextWords.length) || 0;

  // preparing article body
  const articleBody = (typeof articleObj.body !== 'undefined') ? extractTextFromHtml(articleObj.body) : '';
  const articleBodyWords = articleBody.match(/("[^"]+"|[^"\s]+)/g);
  const articleBodyLength = (articleBodyWords && articleBodyWords.length) || 0;

  // fetching the paywall content size string from article meta
  const paywallContentSizeMapping = {
    'Short (50 words)': 50,
    'Small(50 words)': 50,
    small: 50,
    'Medium(80 words)': 80,
    'Medium (80 words)': 80,
    medium: 80,
    'Large (100 words)': 100,
    'Large(100 words)': 100,
    large: 100
  };

  const paywallContentSize =
    (typeof articleMeta.paywall_content_size !== 'undefined')
      ? paywallContentSizeMapping[articleMeta.paywall_content_size]
      : 50;

  if ((articleTitleLength + articleLeadTextLength) < paywallContentSize) {
    var restOfTheContentSize = paywallContentSize - (articleTitleLength + articleLeadTextLength);

    if (articleBodyLength <= restOfTheContentSize) {
      bodyText = articleBody;
    } else if (articleBodyLength > restOfTheContentSize) {
      bodyText = articleBodyWords.slice(0, restOfTheContentSize).join(' ');
    }
    title = articleTitle;
    leadText = articleLeadText;
  } else if ((articleTitleLength + articleLeadTextLength) === paywallContentSize) {
    title = articleTitle;
    leadText = articleLeadText;
  } else {
    if (articleTitleLength > paywallContentSize) {
      title = articleTitleWords.slice(0, paywallContentSize).join(' ');
    } else if (articleTitleLength > paywallContentSize) {
      title = articleTitleWords.slice(0, paywallContentSize).join(' ');
    } else if (articleLeadTextLength > (paywallContentSize - articleTitleLength)) {
      title = articleTitle;
      leadText = articleLeadTextWords.slice(0, (paywallContentSize - articleTitleLength)).join(' ');
    }
  }

  return { title, leadText, bodyText };
}
